<template>
    <div class="infoBox">
        <searchForm :item-list="searchFormList" @search="search" @reset="reset"/>
        <!-- 新增 -->
        <div class="addButton">
            <el-button class="button" type="primary" @click="addDict(activeName)" v-if="UserPerm.hasPerm(Constant.PermMaterialModelUpload)" >上传模型</el-button>
        </div>
        <div class="tableBox">
          <div class="content" v-if="dataList">
              <div class="infoItem" v-for="(i,index) in dataList" :key="index">
                  <div class="infoImg">
                      <div class="imgBox">
                          <img :src="OSSURL+i.preview" alt="" v-if="i.isFinish===1 && i.hasError===0">
                      </div>
                      <div class="btnBox">
                          <el-button type="primary" size="small"  @click="edit(i.id)">编辑</el-button>
                          <el-button type="danger" size="small"   @click="deleteBtn(i.id)">删除</el-button>
                      </div>
                      <div class="textBox" v-if="i.isFinish===0">
                          {{i.stateName}}
                      </div>
                      <div class="textBox" v-if="i.isFinish===1 && i.hasError===1">
                          {{i.reason}}
                      </div>
                  </div>
                  <div class="infoText">
                    <el-popover
                      placement="bottom-start"
                      :width="200"
                      trigger="hover"
                      :content="i.name"
                    >
                      <template #reference>
                        <div class="modelTitle">
                          {{i.name}} 
                        </div>
                      </template>
                    </el-popover>
                  </div>
              </div>
          </div>
          <div class="empt" v-else>
              暂无数据
          </div>
          <div class="pageBox" v-if="total > 0" >
              <page 
                  :current-page="query.page" 
                  :page-size="query.size" 
                  :total-items="total"
                  @current="handleCurrentChange" 
                  @size="handleSizeChange" 
              />
          </div>
        </div>
        <el-dialog
            v-model="dialogVisible"
            title="编辑信息"
            width="500"
            :before-close="handleClose"
            :close-on-click-modal="false"
        >
            <el-form :model="form" label-width="120px" ref="ruleFormRef" :rules="rules">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name"  placeholder="请输入"/>
                </el-form-item>
                <el-form-item label="模型分类" prop="category">
                    <el-cascader v-model="form.category" :options="options" :props="props" clearable 
                    :show-all-levels="false" @change="casChange" style="width:100%;"/>
                </el-form-item>
                <el-form-item label="模型摆放位置" prop="position">
                    <el-select v-model="form.position"  style="width:100%;">
                        <el-option v-for="i in modelPosition" :key="i.key" :label="i.value" :value="i.key"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="价格" prop="price">
                    <el-input v-model="form.price"  placeholder="请输入"/>
                </el-form-item>
                <div class="formItemBox" v-for="(item,index) in tagList" :key="index">
                  <el-form-item :label="item.name">
                    <el-select v-model="form['tagList'+index]"  style="width:100%;">
                        <el-option v-for="i in item.children" :key="i.id" :label="i.name" :value="i.id"/>
                    </el-select>
                  </el-form-item>
                </div>
                <el-form-item label="系统内置" v-if="userIdentity===Constant.UserTypePlatform">
                    <el-radio-group v-model="form.isSystem">
                        <el-radio :value="1">是</el-radio>
                        <el-radio :value="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="推荐首页" v-if="userIdentity===Constant.UserTypePlatform">
                    <el-radio-group v-model="form.isHome">
                        <el-radio :value="1">是</el-radio>
                        <el-radio :value="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="模型特性" prop="feature" v-if="userIdentity===Constant.UserTypePlatform">
                    <el-cascader v-model="form.feature" :options="options1" :props="props" clearable 
                    :show-all-levels="false" @change="casChange1" style="width:100%;"/>
                </el-form-item>
                <div class="customTagNames" v-if="userIdentity===Constant.UserTypePlatform">
                  <div class="customTitle">
                    <div class="nameTitle">别名列表</div>
                    <div class="nameAdd" @click="add">新增别名</div>
                  </div>
                    <div class="TagNamesBox" v-for="(item, index) in TagNamesArr" :key="index">
                        <el-input v-model="item.TagName" placeholder="请输入别名">
                          <template #append>
                            <span class="btnDel" @click="del(index)">删除</span>
                          </template>
                        </el-input>
                    </div>
                </div>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="handleClose">取消</el-button>
                    <el-button type="primary" @click="onSubmit(ruleFormRef)"
                    >提交</el-button
                    >
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import api from '@/api/api'
import { ref,reactive,computed } from 'vue'
import Constant from '@/constant/constant';
import UserPerm from '@/constant/userPerm';
import PersonPerm from '@/constant/personPerm';
import {useRouter} from 'vue-router'
import { OSSURL} from '../../util/file'
import page from '@/components/common/page';
import dataFactory from '@/api/dataFactory';
import messageBox from '@/constant/message';
import searchForm from '@/components/common/search';
import { useStore } from 'vuex'
export default {
  components: {
    page,searchForm,
  },
  props: ['activeName','materialType'],
  setup(prop) {
    const vue = useStore()
    const rules = reactive({
        category: [{ required: true, message: '分类不能为空', trigger: 'change' }],
        position: [{ required: true, message: '位置不能为空', trigger: 'change' }],
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        price: [{ required: true, message: '价格不能为空', trigger: 'blur' }],
    })
    const props = {
        checkStrictly: true,
        children: 'children',
        label: 'nodeName',
        value:'nodeId',
    }
    const tagList = ref([])
    const dialogVisible = ref(false)
    const TagNamesArr = ref([])
    const form = ref({
      isSystem:0,
      isHome:1,
    })
    const setForm = ref({})
    const ruleFormRef = ref()
    const options = ref([])
    const options1 = ref([])
    const modelPosition = ref([])
    const userIdentity = PersonPerm.identity()
    const router = useRouter()
    const activeName = ref(prop.activeName)
    const materialType = ref(prop.materialType)
    const loading = ref(false)
    const dataList = ref([])
    const query = ref({
        page: Constant.PaginationDefaultPage,
        size: Constant.PaginationDefaultSize,
        group:materialType.value
    })
    const total = ref(0)
    //function
    //获取字典值
    dataFactory.DictList([Constant.DictCodeModelPosition]).then(res => {
        modelPosition.value = res[Constant.DictCodeModelPosition]
    })
    //获取资源
    api.TagList({pid: Number(0)}).then((resp) => {
      const arr = resp?.result || []
      arr.forEach(res => {
        if(res.tagType ===2 ) {
          api.TagList({pid: res.id}).then((resp) => {
            res.children = resp.result
            tagList.value.push(res)
          }).catch((err) => {
            ;
          });
        }
      })
    }).catch((err) => {
      ;
    });
    //获取分类
    const DictFaList = () =>{
        api.TreeView({bizCode:Constant.TreeModel,nodeId:'0',}).then((resp) => {
          options.value = resp.result.children?.filter(res => {
            return res.state === 1
          }).map(item => {
            item.children = item.children?.filter(items => items.state === 1)
            return item
          }) || []
        }).catch((err) => {
            ;
        });
    }
    DictFaList()
    //获取特性
      api.TreeView({bizCode:Constant.TreeModelFeature,nodeId:'0',}).then((resp) => {
          if(resp?.result) {
            options1.value = resp.result.children || []
          }
      }).catch((err) => {
          
      });
    
    const searchFormList = computed(() => {
        return [
            {label: '模型名称', prop: 'name', type: 'input'},
            {label: '模型分类', prop: 'categoryList', type: 'cascader', list: options.value, props:{...props,multiple: true,}},
        ]
    })
    const search = (form) => {
      let arr = form.categoryList?.map(i => {
        return Number(i)
      })
      setForm.value = {...form,categoryList:arr}
      getDataList(setForm.value)
    }
    const reset = () => {
        query.value.page = Constant.PaginationDefaultPage
        query.value.size = Constant.PaginationDefaultSize
        setForm.value={}
        getDataList()
    }
    const add = () => {
      TagNamesArr.value.push({
        TagName:'',
      })
    }
    const del = (i) => {
      TagNamesArr.value.splice(i,1)
    }
    const handleCurrentChange = (val) => {
        query.value.page = val
        getDataList()
    }
    const handleSizeChange = (val) => {
        query.value.size = val
        getDataList()
    }
    //模型上传
    const addDict = (activeName) => {
      if(activeName === Constant.TreeModel) {
        router.push({ path: '/m/materialManage/materialUpload', query: { materialType: materialType.value }})
      }
    }
    //获取列表数据
    const getDataList = () => {
        api.ModelList({...query.value,...setForm.value}).then((resp) => {
          dataList.value = resp.result
          total.value = resp.total
        }).catch((err) => {
            ;
        });
    }
    getDataList()
    //模型分类处理结果
    const edit = (id) => {
        api.ModelView({id:id}).then((resp) => {
          if(resp.customTagNames) {
            TagNamesArr.value = []
            resp.customTagNames.filter(res => {
              TagNamesArr.value.push({
                TagName:res
              })
            })
          }else {
            TagNamesArr.value.push({
              TagName:'',
            })
          }
          form.value = {
            ...resp,
            category:resp.category+"",
            position:resp.position+"",
            feature:resp.feature+"",
          }
          if(form.value.tagIds?.length>0){
            tagList.value.filter((item,index) => {
              item.children.filter(item1 => {
                if(form.value.tagIds.includes(item1.id)) {
                  form.value["tagList"+index] = item1.id
                }
              })
            })
          }
          
        }).catch((err) => {
            ;
        });
      dialogVisible.value = true
    }
    //删除
    const deleteBtn = (id) => {
        messageBox.elMessageBox().then(() => {
          api.ModelState({id: id, state: Constant.StateDelete}).then((resp) => {
            messageBox.success('删除成功!');
            getDataList()
          }).catch((err) => {
            ;
          });
        }).catch((err) => {
            messageBox.info('已取消删除!');
        });
    }
    //模型分类处理结果
    const casChange = (i) => {
        form.value.category = i.slice(-1)[0]
    }
    const casChange1 = (i) => {
        form.value.feature = i.slice(-1)[0]
    }
    //关闭弹窗
    const handleClose = () => {
      form.value = {
        isSystem:0,
        isHome:1,
      }
      TagNamesArr.value = []
      dialogVisible.value = false
    }
    //确定提交
    const onSubmit = (formEl) => {
        let arrTag = []
        for(let key in form.value) {
          if(key.indexOf('tagList') > -1 && form.value[key]) {
            arrTag.push(form.value[key])
          }
        }
        form.value.customTagNames = []
        form.value.tagIds = arrTag
        if(TagNamesArr.value.length>0) {
          TagNamesArr.value.map(res => {
            form.value.customTagNames.push(res.TagName)
          })
        }
        if (!formEl) return
        formEl.validate((valid) => {//对表单进行一个校验，接收一个回调函数，返回promise
            if (valid) {
              form.value = {
                ...form.value,
                category:parseInt(form.value.category),
                position:parseInt(form.value.position),
                feature:parseInt(form.value.feature),
                price:Number(Number(form.value.price).toFixed(2)),
              }
              api.ModelUpdate({...form.value}).then((resp) => {
                messageBox.success('修改成功!');
                dialogVisible.value = false
                getDataList()
                TagNamesArr.value = []
              }).catch((err) => {
                ;
                form.value.category=form.value.category+"";
                form.value.position=form.value.position+"";
                form.value.feature=form.value.feature+"";
              });
            } else {
                return false
            }
        })
    }
    return {
        TagNamesArr,
        activeName,
        materialType,
        Constant,
        query,
        loading,
        dataList,
        OSSURL,
        total,
        rules,
        ruleFormRef,
        props,
        dialogVisible,
        form,
        modelPosition,
        options,
        options1,
        searchFormList,
        tagList,
        UserPerm,
        userIdentity,
        add,
        del,
        getDataList,
        addDict,
        handleCurrentChange,
        handleSizeChange,
        handleClose,
        onSubmit,
        casChange,
        casChange1,
        edit,
        deleteBtn,
        search,
        reset,
    }
  },
}
</script>
<style lang="scss" scoped>
.customTagNames {
  font-family: "Microsoft YaHei", 微软雅黑, "MicrosoftJhengHei", 华文细黑, STHeiti, MingLiu;
  overflow: hidden;
  .TagNamesBox {
    padding-bottom:10px; 
    width:calc(100% - 51px);
    float: right;
  }
  .btnDel {
    color: #f56c6c;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: #f89898;
    }
  }
  .customTitle {
    overflow: hidden;
    padding-bottom: 10px;
    .nameTitle {
      font-size: 14px;
      line-height: 24px;
      color: #606266;
      float: left;
      margin-left: 51px;
    }
    .nameAdd {
      padding: 5px 10px;
      font-size: 12px;
      float: right;
      color: #409eff;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        color: #79bbff;
      }
    }
  }
}
::v-deep .el-tabs__nav-wrap {
  background: #fff;
  padding: 0 20px;
}

.inline {
    display: inline-flex;
}
.right_content {
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  background-color: #fafbfe;
  min-height: calc(100% - 68px);
  display: flex;
  flex-direction: column;
  .infoBox {
    display: flex;
    flex-direction: column;
  }
  .tableBox {
      margin-top: 30px;
      width: 100%;
      .empt {
        text-align: center;
        font-size: 20px;
        margin-top: 40px;
      }
      .pageBox {
        display: flex;
        margin:20px 0 ;

      }
      .content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        margin-left: -20px;
        .infoItem {
          width: 258px;
          height: 260px;
          padding-bottom: 20px;
          background: #fff;
          box-shadow: 0 0 20px 0 rgba(14,14,14,.06);
          margin-top: 20px;
          margin-left: 20px;
          position: relative;
          .infoImg {
            margin: 20px;
            width: 220px;
            height: 206px;
            border-radius: 4px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .imgBox {
              width: 100%;
              height: 100%;
              img {
                width: 100%;
                height: 100%;
                object-fit: fill;
              }
            }
            .btnBox {
              position: absolute;
              bottom: 10px;
              right: 0;
              padding: 10px 10px 0 0;
              font-size: var(--smallFont);
              flex-direction: row;
              color: #fff;
              display: none;
            }
            .textBox {
              position: absolute;
              text-align: center;
              font-size: 18px;
              flex-direction: row;
              color: red;

            }
          }
          .infoText {
            margin-top: 0;
            margin-left: 19px;
            margin-right: 19px;
            overflow: hidden;
            .modelTitle {
              max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
              font-weight: 700;
              opacity: .8;
              margin-bottom: 10px;
            }
          }
          
          &:hover {
            color: #1a7af8;
            .infoImg {
              background: rgba(137, 207, 240,.2);
            }
            .btnBox {
              display: flex;
            }
          }
        }
      }
  }
  .header {
    width: 100%;
  }
  .addButton {
    width: 100%;
    .button {
      float: right;
    }
  }
}
</style>
