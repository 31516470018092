<template>
  <div class="mune">
		<div id="login_container">
			
		</div>
  </div>
</template>

<script setup>
import { reactive,ref,defineEmits,onMounted } from 'vue'
import {useStore} from "vuex"
import { useRouter } from 'vue-router'

const router = useRouter()
const store = useStore()

const setWxerwma = () => {
  let obj = new WxLogin({
    self_redirect:false,
    id:"login_container", 
    appid: store.state.user.wxLogin.appid, 
    redirect_uri: encodeURIComponent(process.env.VUE_APP_WX_LOGIN),
    scope: 'snsapi_login',
    state: store.state.user.wxLogin.state, //建议使用时间戳+随机数，具体看你
    href:"data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSAgewogIHdpZHRoOiAxNjBweDsKICBtYXJnaW4tdG9wOiAxNXB4OwogIGJvcmRlcjogMXB4IHNvbGlkICNlMmUyZTI7Cn0KLnRpdGxlIHsKICBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5zdGF0dXMgcDpudGgtY2hpbGQoMikgewogIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLnN0YXR1cyB7CiAgICBwYWRkaW5nOiA3cHggMTRweDsKICAgIHRleHQtYWxpZ246IGNlbnRlcjsKfQ==",
  })
}

const WxLogin = function (e) {
  var r = "default";
  !0 === e.self_redirect ? (r = "true") : !1 === e.self_redirect && (r = "false");
  var n = document.createElement("iframe"),
    i =
      "https://open.weixin.qq.com/connect/qrconnect?appid=" +
      e.appid +
      "&scope=" +
      e.scope +
      "&redirect_uri=" +
      e.redirect_uri +
      "&state=" +
      e.state +
      "&login_type=jssdk&self_redirect=" +
      r +
      "&styletype=" +
      (e.styletype || "") +
      "&sizetype=" +
      (e.sizetype || "") +
      "&bgcolor=" +
      (e.bgcolor || "") +
      "&rst=" +
      (e.rst || "");
  (i += e.style ? "&style=" + e.style : ""),
    (i += e.href ? "&href=" + e.href : ""),
    (i += "en" === e.lang ? "&lang=en" : ""),
    (n.src = i),
    (n.frameBorder = "0"),
    (n.allowTransparency = "true"),
    (n.scrolling = "no"),
    (n.width = "300px"),
    (n.height = "242px");
  var s = document.getElementById(e.id);
  s.innerHTML = "", s.appendChild(n);
}
onMounted(() => {
	setWxerwma()
});
</script>

<style lang="less" scoped>
.mune {
  height: 100%;
}
.itemBox {

}
.demo-ruleForm {
  margin-top: 30px;
  height: 100%;
}
.passBtn {
  text-align: right;
  padding: 10px 0;
  font-size: 12px;
  color: #409eff;
  cursor: pointer;
}
.topStyle {
	margin-top: 30px;
}
.impowerBox .qrcode  {
  width: 160px;
  margin-top: 15px;
  border: 1px solid #e2e2e2;
}
.title {
  display: none;
}
.impowerBox .status p:nth-child(2) {
  display: none;
}
.impowerBox .status {
    padding: 7px 14px;
    text-align: center;
}
</style>
