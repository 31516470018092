export const comparePriceList = [
	{type: 'header', name: '渲染出图', educ: '教育版', busi: '商务版'},
	{type: 'content', name: '普通图/俯视图', educ: '最高1K', busi: '4K及以下'},
	{type: 'content', name: '全景图', educ: '最高2K', busi: '5K及以下'},
	{type: 'content', name: '环拍', educ: '最高2K', busi: '最高4K'},
	{type: 'content', name: '无限渲染时段', educ: '免费', busi: '全天24小时'},
	{type: 'content', name: '会员专属渲染券', educ: 'icon-close', busi: '无限渲染'},
	{type: 'content', name: '渲染加速', educ: 'icon-close', busi: '提速100%'},
	{type: 'content', name: '同时渲染', educ: '1张', busi: '3张'},
	{type: 'content', name: '渲染券增购', educ: 'icon-close', busi: '最高32K'},
	{type: 'header', name: '模型素材', educ: '', busi: ''},
	{type: 'content', name: '会员模型包（每周更新）', educ: 'icon-close', busi: 'icon-select'},
	{type: 'content', name: '定制设计精品库', educ: 'icon-close', busi: 'icon-select'},
	{type: 'content', name: '定制窗帘精品库', educ: 'icon-close', busi: 'icon-select'},
	{type: 'content', name: '硬装素材上传', educ: 'icon-close', busi: 'icon-select'},
	{type: 'header', name: '全景营销', educ: '', busi: ''},
	{type: 'content', name: '智能美化', educ: 'icon-select', busi: 'icon-select'},
	{type: 'content', name: '去图布斯水印', educ: 'icon-select', busi: 'icon-select'},
	{type: 'content', name: '自定义音乐上传', educ: 'icon-select', busi: 'icon-select'},
	{type: 'content', name: '作品加密防被盗', educ: 'icon-select', busi: 'icon-select'},
	{type: 'content', name: '小行星开场动画', educ: 'icon-select', busi: 'icon-select'},
	{type: 'content', name: '头像、个人水印、联系方式', educ: 'icon-close', busi: 'icon-select'},
	{type: 'header', name: '定制施工', educ: '', busi: ''},
	{type: 'content', name: '定制专业图纸', educ: 'icon-select', busi: 'icon-select'},
	{type: 'content', name: '定制清单', educ: 'icon-select', busi: 'icon-select'},
	{type: 'content', name: '每层120-1000㎡装修图纸', educ: 'icon-select', busi: 'icon-select'},
	{type: 'content', name: '定制基础物料报价模板', educ: 'icon-close', busi: 'icon-select'},
	{type: 'header', name: '视频渲染', educ: '', busi: ''},
	{type: 'content', name: '1080P', educ: 'icon-close', busi: '5次/周'},
	{type: 'content', name: '720P', educ: '20次/周', busi: '50次/周'},
	{type: 'content', name: '480P', educ: '50次/周', busi: '100次/周'},
	{type: 'header', name: 'AI出图', educ: '', busi: ''},
	{type: 'content', name: '小图生成次数', educ: '无限次', busi: '无限次'},
	{type: 'content', name: '高清画质升级', educ: '20次/日', busi: '20次/日'},
	{type: 'content', name: '超清画质升级', educ: '20次/日', busi: '20次/日'},
	{type: 'header', name: '数字资产', educ: '', busi: ''},
	{type: 'content', name: '回收站方案永久保留', educ: 'icon-select', busi: 'icon-select'},
	{type: 'content', name: '月度数据报告', educ: 'icon-select', busi: 'icon-select'},
	{type: 'content', name: '方案历史版本保留天数', educ: '近90天', busi: '近90天'},
	{type: 'header', name: '身份特权', educ: '', busi: ''},
	{type: 'content', name: '会员身份标识', educ: 'icon-select', busi: 'icon-select'},
	{type: 'content', name: '线上运营顾问', educ: 'icon-select', busi: 'icon-select'},
	{type: 'content', name: '线上培训课程', educ: 'icon-close', busi: 'icon-select'},
	{type: 'header', name: '营销获客', educ: '', busi: ''},
	{type: 'content', name: '找设计师频道', educ: '免费入驻', busi: '线索管理'},
]


export const productLinks = [
	[
		{name: '产品与服务', link: ''},
		{name: '3D云设计', link: ''},
		{name: '优秀案例', link: ''},
		{name: '模型库', link: ''},
		{name: '花园百科', link: ''},
		{name: '学习中心', link: ''},
		{name: '找设计师', link: ''},
	],
	[
		{name: '热门模板', link: ''},
		{name: '新中式花园模板', link: ''},
		{name: '现代花园模板', link: ''},
		{name: '禅意花园模板', link: ''},
		{name: '田园花园模板', link: ''},
		{name: '露营花园模板', link: ''},
	],
	[
		{name: '下载', link: ''},
		{name: '图布斯客户端', link: ''},
		{name: '图布斯设计师APP', link: ''},
		{name: '图布斯小程序', link: ''},
	],
	[
		{name: '其他', link: ''},
		{name: '用户服务协议', link: '/agreement'},
		{name: '隐私政策', link: '/privacy'},
		{name: '举报', link: ''},
	],
	[
		{name: '关于我们', link: ''},
		{name: '新闻中心', link: ''},
		{name: '集团公益', link: ''},
		{name: '图布斯·红心奖', link: ''},
		{name: '加入我们', link: ''},
		{name: '帮助文档', link: ''},
		{name: '安全和合规', link: ''},
	]
]

export const primaryClassification = ['硬装', '软装', '植物', '景观鱼池', '亮化灯具', '花园构筑物'];
export const secondaryClassification = ['全部', '亭子', '地面', '地拼组合', '景墙', '围栏', '围边', '花园门', '景观石'];

