<template>
    <div class="right_content">
        <div class="header">
            <el-tabs v-model="activeName" class="demo-tabs"  @tab-change="handleClick" type="card">
                <el-tab-pane label="模型" :name="Constant.TreeModel" v-if="UserPerm.hasPerm(Constant.PermMaterialModel)"></el-tab-pane>
                <el-tab-pane label="材质" :name="Constant.TreeTexture"  v-if="UserPerm.hasPerm(Constant.PermMaterialTexture)"></el-tab-pane>
            </el-tabs>
            <el-tabs v-model="materialType" class="demo-tabs" @tab-change="userClick">
                <el-tab-pane v-for="(i,index) in tabList" :key="index" :label="i.label" :name="i.name"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="infoBox" v-if="activeName === Constant.TreeModel">
            <Model v-if="flag" :activeName="activeName" :materialType="materialType"/>
        </div>
        <div class="infoBox" v-if="activeName === Constant.TreeTexture">
            <Texture v-if="flag" :activeName="activeName" :materialType="materialType" />
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import Constant from '@/constant/constant';
import UserPerm from '@/constant/userPerm';
import Model from './model';
import Texture from './texture';
import {useRoute} from 'vue-router'
import PersonPerm from '@/constant/personPerm';
export default {
  components: {
    Model,Texture
  },
  setup() {
    const flag = ref(true)
    const route = useRoute()
    const userIdentity = PersonPerm.identity()
    const tabList = Constant.UserTypeTabs(userIdentity)
    const fistActive = tabList?.[0]?.name
    const activeName = ref(route.query.active || Constant.TreeModel)
    const materialType = ref(fistActive)
    //function
    //切换选项卡
    const handleClick = () => {
      materialType.value = fistActive
    }
    const userClick = () => {
      flag.value = false
      setTimeout(() => {
        flag.value = true
      }, 300)
    }
    
    return {
        activeName,
        materialType,
        Constant,
        tabList,
        flag,
        UserPerm,
        handleClick,
        userClick,
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__nav-wrap {
  background: #fff;
  padding: 0 20px;
}
.right_content {
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  background-color: #fafbfe;
  min-height: calc(100% - 68px);
  display: flex;
  flex-direction: column;
  .header {
    width: 100%;
  }
  .infoBox {
    width: 100%;
  }
}
</style>
