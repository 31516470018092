<template>
  <div class="nav-header">
    <div class="logo">
      <img style="cursor: pointer;" src="@/assets/images/logo.png" alt="" @click="this.$router.push('/')">
      <span class="version">V1.0</span>
      <span class='dropdown'>
        <span class="el-dropdown-link">
          {{Identity}}
        </span>
      </span>
    </div>
    <div class="content">
     	
		<el-button style="background: #cf3861;borderColor: #cf3861;margin-left:12px;"  type="primary" size="small" @click="this.$router.push('/')">官网</el-button>
      	<el-button style="margin-left:12px;"  type="primary" size="small" @click="routerToDesign" v-if="userStatus">开始设计</el-button>
		<el-popover placement="bottom" :width="200" trigger="click" v-else>
			<div class="phoneBox">
			<div class="label">
				<el-icon><Phone /></el-icon>
			</div>
			<div class="value">
				0314-2539999
			</div>
			</div>
			<div class="imgBox">
							<img :src="OSSURL + 'home/sell.png'" alt="">
			</div>
			<template #reference>
			<el-button  type="primary" size="small">开始设计</el-button>
			</template>
		</el-popover>
	  	<el-popover
			placement="bottom"
			:width="300"
			trigger="hover"
		>

			<div class="popover-content">
				<ul>
					<li v-for="item in messageData" @click="this.$router.push(`/person/message/${item.msgType == 1 ? 'notice' : 'notification'}`)">
						<el-badge :value="item.total" :max="99" :hidden="!item.total" class="item">
							<img :src="item.img" alt="">
						</el-badge>
						<div class="message-content">
							<p>{{ item.title }}</p>
							<span>{{ item.content }}</span>
						</div>
					</li>
				</ul>
				<el-button type="info" link @click="this.$router.push('/person/message/notice')">
					查看全部消息
				</el-button>
			</div>

			<template #reference>
				<el-badge :is-dot="!!msgDotCount" :offset="[-3, 3]" :max="99" :hidden="!msgDotCount" class="item">
					<el-button
						style="margin-left:12px; font-size: 20px;"
						type="primary"
						size="small"
						link
						@click="getMessageBox"
					>
						<el-icon ><Bell/></el-icon>
					</el-button>
				</el-badge>
			</template>

		</el-popover>
      <div class="user-container">
				<el-popover placement="bottom" :width="300" trigger="hover">
          <div class="perInfo">
            <div class="header">
              <div class="box">
                <div class="left">
                  <img class="perImg" :src="OSSURL + userView?.avatar" alt="">
                </div>
                <div class="right">
                  <div class="rightT">
                    {{userView?.nickName}}
                  </div>
                  <div class="rightB">
                    ID:  {{userView?.userName}}
                  </div>
                </div>
              </div>
            </div>
            <div class="footer">
              <div class="menuList">
                <div class="menuItem" @click="routerToSetting">
                  <el-icon><Setting /></el-icon>
                  <span>个人中心</span>
                  
                </div>
                <div class="menuItem" @click="userLogout">
                  <el-icon><CircleClose /></el-icon>
                  <span>退出</span>
                  
                </div>
              </div>
            </div>
          </div>
					<template #reference>
            <div class="flexDiv">
              <img class="avatar" :src="OSSURL + userView?.avatar" alt="">
              <el-icon><ArrowDown /></el-icon>
            </div>
					</template>
				</el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import Constant from '@/constant/constant';
import PersonPerm from '@/constant/personPerm';
import { useStore } from 'vuex';
import {useRouter} from 'vue-router';
import Cookies from 'js-cookie';
import { OSSURL} from '@/util/file';
import {
	getNotiBoxApi
} from '@/api/notification.js';
export default {
  setup() {
    const router = useRouter()
    const store = useStore()
    const userView = store.state.user.userView
    const userStatus = PersonPerm.design()
    const Identity = PersonPerm.identityZh()
    const messageData = ref([]);
    const msgDotCount = ref(0);
	onMounted(() => {
		getMessageBox();
	})
    const userLogout = () => {
      localStorage.clear()
      Cookies.remove(Constant.HttpAccessToken,{domain: process.env.VUE_APP_DESIGN_DOMAIN})
      window.location.href = '/'
    };
    const routerToSetting = () => {
        router.push({ path: '/person/setting/personSetting'})
    };
 
    const routerToDesign = () => {
        window.open(process.env.VUE_APP_DESIGN_URL)
    };

	// 获取导航栏消息概览
	const getMessageBox = () => {
		getNotiBoxApi().then(res => {
			msgDotCount.value = res.total;
			messageData.value = res?.result.map(item=>{
				item.img = item.msgType === 1 ? require(`@/assets/images/notice.png`) : require(`@/assets/images/notification.png`);
				return item;
			});
		});
	}

    return {
	messageData,
	msgDotCount,
      Constant,
      Identity,
      userStatus,
      userView,
      OSSURL,
      userLogout,
      routerToDesign,
      routerToSetting,
	  getMessageBox,
    }
  }
}

</script>
<style lang="scss" scoped>
* ul,li {
	list-style: none;
	padding: 0;
}
p {
	margin: 0;
}
.user-container {
  margin-left: 12px;
  .avatar {
    height: 20px;
    width: 20px;
    border-radius: 10px;
  }
  .flexDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.perInfo {
  width: 276px;
  box-sizing: border-box;
  .footer {
    box-sizing: border-box;
    .menuList {
      .menuItem {
        padding: 8px 12px;
        background-color: transparent;
        box-sizing: border-box;
        position: relative;
        user-select: none;
        height: 36px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        border-radius: 8px;
        color: rgb(96, 101, 108);
        cursor: pointer;
        border-color: rgb(232, 232, 232);
        border-width: 0px;
        text-decoration: none;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        vertical-align: middle;
          display: flex;
        justify-content: flex-start;
        span {
          margin-left: 10px;
        }
        &:hover {
          background-color: rgba(146,152,155,0.08);
        }
      }
    }
  }
  .header {
    padding: 8px 12px;
    .box {
      display: flex;
      align-items: center;
      .left {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 12px;
          position: relative;
          flex-shrink: 0;
        .perImg {
          width: 50px;
          height: 50px;
          border-radius: 25px;
        }
      }
      .right {
        width: 0;
        flex-grow: 1;
        .rightT {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 600;
        }
        .rightB {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}
.phoneBox {
	font-size: 14px;
	font-weight: 700;
}
.imgBox {
	margin-top: 10px;
	img {
		width: 180px;
	}
}
.label {
	display: inline-block;
}
.value {
	display: inline-block;
	padding-left: 10px;
}
.dropdown {
  margin-left: 10px;
}
.version {
  font-size: 8px;
  position: relative;
  top: -5px;
  margin-left: 2px;
  font-weight: 600;
}
</style>

<style lang="scss" >
  .head_box{
    height:calc(var(--headHeight) - 4px);
    box-shadow: 0 0 4px rgba($color: #000000, $alpha: 0.4);
  }
 .nav-header{
   height: 60px;
   width: 100%;
  position: fixed;
  //  background-color: var(--btnColor);
  background: #fff;
   box-shadow:0px 0px 10px 0px rgba(0, 28, 65, 0.2); 
   display: flex;
   justify-content: space-between;
   
  .logo{
    height:calc(100% - 4px);
    padding: 2px 0;
    width: 306px;
    // background-color:var(--btnColor);
    // box-shadow: 0 0 4px rgba($color: #000000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 12;
    img{
      position: relative;
      width: auto;
      height: 25px;
      margin-left: 14px;
    }
    
  }
  .content{
    height: 100%;
    // width: 215px;
   // background-color: aqua;
    margin-right: 20px;
    display: flex;
    align-items: center;
    position: relative;
  }
 }

.popover-content {
	display: flex;
	flex-direction: column;
	ul {
		border-bottom: 1px solid #eee;
	}
	ul li {
		display: flex;
		align-items: center;
		margin-bottom: 12px;
		cursor: pointer;
		.message-content {
			margin-left: 20px;
			p {
				font-weight: bold;
			}
			span {
				font-size: 12px;
				color: rgb(146, 152, 159);
			}
		}
		img {
			width: 40px;
			height: 40px;
			margin-right: 0px;
		}
	}
}
</style>

