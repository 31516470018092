import { createRouter, createWebHistory } from 'vue-router'
import Constant from '@/constant/constant';
import store from '@/store'
import HomePage from '../pages/login/login.vue';
import ManageTemp from '../pages/manage.vue';
import BlankTemp from '../pages/routerTo/index.vue';
import PersonalSchema from '../pages/schemLibManage/personScheme.vue';
import schemeManage from '../pages/schemLibManage/schemeManage.vue';
import SettingTemp from '@/pages/person/personSetting.vue';
import PersonMaterial from '../pages/material/personMaterial.vue';
import MaterialUpload from '../pages/material/materialUpload.vue';
import TextureUpload from '../pages/material/textureUpload.vue';
import PriceComp from '@/pages/login/price/index.vue';
import designComp from '@/pages/login/design/index.vue';
import materialComp from '@/pages/login/material/index.vue';
import HomeTemp from '../pages/login/homeTemp.vue';
const routes = [
	{
		path: '/',
		name: 'homePage',
		meta: {
			title: "首页",
			code:'',
			home:true,
		},
		component: HomePage,
	},
	{
		path: '/',
		name: "home",
		meta: {
			title: "首页",
			code:'',
		},
		redirect: 'home',
		component: HomeTemp,
		children: [
			{
				path: 'price',
				name: 'price',
				meta: {
					title: '价格',
					code:'',
					description: 'descriptiondescription',
					// metaInfo: {
					// 	title: "在线工具箱-By Mintimate",
					// 	description:"Mintimate的在线工具箱，致力于打造便民服务。为开发者、Minecraft游戏爱好者，创建方便的在线工具",
					// 	keywords: "在线工具箱,便民根据,站点根据,开发者,开发者实用工具,IP查询,图片处理,Base64转码,字数统计,字符统计,MC服务器检测,Minecraft服务器检测",
					// }
				},
				component: PriceComp,
			},
			{
				path: 'design',
				name: 'design',
				meta: {
					title: '优秀设计',
					code:'',
				},
				component: designComp,
			},
			{
				path: 'f',
				name: 'anthor',
				component: () => import('@/pages/login/anthor/index.vue'),
				redirect: 'f/category/:id',
				children: [
					{
						path: 'category/:id',
						name: "category",
						meta: {
							title: "帮助",
							code: '',
						},
						component: () => import("@/pages/login/anthor/category/index.vue"),
					},
					{
						path: "article/:id",
						name: 'article',
						meta: {
							title: "帮助",
							code: '',
							home:true,
						},
						component: () => import("@/pages/login/anthor/article/index.vue"),
					},
				],
			},
			{
				path: 'materials',
				name: 'materials',
				meta: {
					title: '素材',
					code:'',
				},
				component: materialComp,
			},
			{
				path: 'upgrades',
				name: 'upgrades',
				meta: {
					title: '升级页面',
					code:'',
				},
				component: () => import('@/pages/login/upgrades/index.vue'),
			},
			{
				path: 'agreement',
				name: 'agreement',
				meta: {
					title: '用户服务协议',
					code:'',
				},
				component: () => import('@/pages/login/agreement/index.vue'),
			},
			{
				path: 'protocolUpload',
				name: 'protocolUpload',
				meta: {
					title: '图布斯3D花园云设计平台用户上传协议',
					code:'',
				},
				component: () => import('@/pages/login/protocolUpload/index.vue'),
			},
			{
				path: 'privacy',
				name: 'privacy',
				meta: {
					title: '隐私政策',
					code:'',
				},
				component: () => import('@/pages/login/privacy/index.vue'),
			},
		]
	},
	{
		path: '/404',
		name: '404page',
		component: () => import('@/pages/404/index.vue'),
	},
	{
		path: '/m',
		name: 'manage',
		component: ManageTemp,
		redirect: 'm/schemeManage',
		children: [
			{
				path: 'schemeManage',
				name: 'schemeManage',
				meta: {
					title: '方案',
					code: Constant.PermProject,
					auth:true,
				},
				redirect: 'm/schemeManage/personScheme',
				component: BlankTemp,
				children: [ {
						path: 'personScheme',
						name: 'personScheme',
						meta: {
							title: '我的方案',
							code: Constant.PermProjectSelf,
							show:true,
							auth:true,
						},
						component: PersonalSchema,
					},
					{
						path: 'schemeManage',
						name: 'schemeManage',
						meta: {
							title: '方案详情',
							code: Constant.PermProjectView,
							show:false,
							auth:true,
						},
						component: schemeManage,
					},
				]
			},
			{
				path: 'materialManage',
				name: 'materialManage',
				meta: {
					title: '素材',
					code: Constant.PermMaterial,
					auth:true,
				},
				redirect: 'm/materialManage/personMaterial',
				component: BlankTemp,
				children: [
					{
						path: 'personMaterial',
						name: 'personMaterial',
						meta: {
							title: '我的素材',
							code: Constant.PermMaterial,
							show:true,
							auth:true,
						},
						component: PersonMaterial,
					},
					{
						path: 'materialUpload',
						name: 'materialUpload',
						meta: {
							title: '上传模型',
							code: Constant.PermMaterialModelUpload,
							show:false,
							auth:true,
						},
						component: MaterialUpload,
					},
					{
						path: 'textureUpload',
						name: 'textureUpload',
						meta: {
							title: '上传材质',
							code: Constant.PermMaterialTextureUpload,
							show:false,
							auth:true,
						},
						component: TextureUpload,
					},
				]
			},
			{
				path: 'free',
				name: 'free',
				meta: {
					title: '开放素材',
					code: Constant.FreeMaterial,
					auth:true,
				},
				component: () => import('../pages/freeMaterial/index.vue'),
			},
			{
				path: "resources",
				name: 'resources',
				meta: {
					title: "资源管理",
					code: Constant.PermResources,
					auth:true,
				},
				component: () => import('../pages/routerTo/index.vue'),
				redirect: "m/resources/text",
				children: [{
						path: 'text',
						name: "text",
						meta: {
							title: "文档管理",
							code: Constant.PermResourcesFile,
							show:true,
							auth:true,
						},
						component: () => import("../pages/resources/text.vue"),
					},
					{
						path: 'articleTree',
						name: "articleTree",
						meta: {
							title: "文章分类",
							code: Constant.PermResourcesClass,
							show:true,
							auth:true,
						},
						component: () => import("../pages/systemSet/articleTree.vue"),
					},
				]
			},
			{
				path: "account",
				name: 'account',
				meta: {
					title: "账户权限",
					code: Constant.PermAccount,
					auth:true,
				},
				component: () => import('../pages/routerTo/index.vue'),
				redirect: "m/account/merchantSettlement",
				children: [{
						path: 'merchantSettlement',
						name: "merchantSettlement",
						meta: {
							title: "企业管理",
							code: Constant.PermAccountCompany,
							show:true,
							auth:true,
						},
						component: () => import("../pages/account/merchantSettlement.vue"),
					},{
					path: 'accountManagement',
					name: "accountManagement",
					meta: {
						title: "账号管理",
						code: Constant.PermAccountManage,
						show:true,
						auth:true,
					},
					component: () => import("../pages/account/accountManagement.vue"),
					},{
					path: 'platformPermissions',
					name: "platformPermissions",
					meta: {
						title: "平台权限",
						code: Constant.PermAccountCode,
						show:true,
						auth:true,
					},
					component: () => import("../pages/account/platformPermissions.vue"),
					},{
					path: 'subAccounts',
					name: "subAccounts",
					meta: {
						title: "子账户",
						code: Constant.PermAccountSubManage,
						show:true,
						auth:true,
					},
					component: () => import("../pages/account/subAccounts.vue"),
					},
				]
			},
			{
				path: "systemSet",
				name: 'systemSet',
				meta: {
					title: "系统设置",
					code: Constant.PermSetting,
					auth:true,
				},
				component: () => import('../pages/routerTo/index.vue'),
				redirect: "m/systemSet/dict",
				children: [{
						path: 'dict',
						name: "dict",
						meta: {
							title: "数据字典",
							code: Constant.PermSettingDict,
							show:true,
							auth:true,
						},
						component: () => import("../pages/systemSet/dict.vue"),
					},{
					path: 'material',
					name: "material",
					meta: {
						title: "资源标签",
						code: Constant.PermSettingTag,
						show:true,
						auth:true,
					},
					component: () => import("../pages/systemSet/material.vue"),
					},
					{
					path: 'materialTree',
					name: "materialTree",
					meta: {
						title: "材质分类",
						code: Constant.PermSettingTexture,
						show:true,
						auth:true,
					},
					component: () => import("../pages/systemSet/materialTree.vue"),
					},{
					path: 'modelTree',
					name: "modelTree",
					meta: {
						title: "模型分类",
						code: Constant.PermSettingModel,
						show:true,
						auth:true,
					},
					component: () => import("../pages/systemSet/modelTree.vue"),
					},
					{
						path: 'featuresTree',
						name: "featuresTree",
						meta: {
							title: "模型特性分类",
							code: Constant.PermSettingFeature,
							show:true,
							auth:true,
						},
						component: () => import("../pages/systemSet/featuresTree.vue"),
					},
					{
						path: 'notifications',
						name: "notifications",
						meta: {
							title: "消息通知",
							code: Constant.PermNotification,
							show:true,
							auth:true,
						},
						component: () => import("../pages/notification/index.vue"),
					},
					{
						path: 'render',
						name: "render",
						meta: {
							title: "渲染权益",
							code: Constant.PermRenderRight,
							show:true,
							auth:true,
						},
						component: () => import("../pages/systemSet/render.vue"),
					},
					{
						path: 'equityTemplate',
						name: "equityTemplate",
						meta: {
							title: "权益模板",
							code: Constant.PermEquityTemplate,
							show:true,
							auth:true,
						},
						component: () => import("../pages/systemSet/equityTemplate.vue"),
					},
					{
						path: 'memberBenefits',
						name: "memberBenefits",
						meta: {
							title: "会员权益",
							code: Constant.PermMemberBenefits,
							show:true,
							auth:true,
						},
						component: () => import("../pages/systemSet/memberBenefits.vue"),
					}
				]
			},
			{
				path: "missionSettings",
				name: 'missionSettings',
				meta: {
					title: "任务设置",
					code: Constant.MissionSetting,
					auth: false,
				},
				component: () => import('../pages/routerTo/index.vue'),
				children: [
					{
						path: 'price',
						name: "missionSettings/price",
						meta: {
							title: "预缴金额设置",
							code: Constant.MissionSettingPrice,
							show: true,
							auth: true,
						},
						component: () => import("../pages/missionSettings/price/index.vue"),
					},
					{
						path: "audit",
						name: 'audit',
						meta: {
							title: "任务审核",
							code: Constant.MissionSettingAudit,
							show: true,
							auth:true,
						},
						component: () => import('../pages/audit/index.vue'),
					},
				]
			}
			
		]
	},
	{
		path: '/person',
		name: 'person',
		component: SettingTemp,
		redirect: 'person/setting',
		children: [
		{
			path: 'setting',
			name: 'setting',
			meta: {
				title: '通用设置',
				code: '',
				auth:true,
			},
			redirect: 'person/setting/personSetting',
			component: () => import('@/pages/routerTo/index.vue'),
			children: [
				{
					path: 'personSetting',
					name: 'personSetting',
					meta: {
						title: '个人设置',
						code: '',
						show:true,
						auth:true,
					},
					component: () => import('@/pages/person/setting/index.vue'),
				},
				{
				path: 'security',
				name: 'security',
				meta: {
					title: '账号安全',
					code: '',
					show:true,
					auth:true,
				},
				component: () => import('@/pages/person/security/index.vue'),
				},

			]
		},
		{
			path: 'message',
			name: 'message',
			meta: {
				title: '消息通知',
				code: '',
				auth:true,
			},
			// redirect: 'person/message/notice',
			component: () => import('@/pages/routerTo/index.vue'),
			children: [
				{
					path: 'notice',
					name: 'notice',
					meta: {
						title: '系统公告',
						code: '',
						show:true,
						auth:true,
					},
					component: () => import('@/pages/person/message/notice.vue'),
				},
				{
					path: 'notification',
					name: 'notification',
					meta: {
						title: '系统通知',
						code: '',
						show:true,
						auth:true,
					},
					component: () => import('@/pages/person/message/notification.vue'),
				},
			],
		},
		{
			path: 'tagSettings',
			name: 'tagSettings',
			meta: {
				title: '个人资料',
				code: '',
				auth:true,
			},
			component: () => import('@/pages/tagSettings/index.vue'),
		}
		]
	},
	{
		path: '/personPage',
		name: "personPage",
		meta: {
			title: "个人主页",
			code:'',
			home:true,
		},
		component: () => import("../pages/personPage/index.vue"),
	},

]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})
function isUserLoggedIn() {
	// 这里应该是检查用户是否登录的逻辑，例如检查token或者session
	return localStorage.getItem(Constant.HttpAccessToken) || false;
}
router.beforeEach((to, from, next) => {
	const isMobile =  navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
	// 用户服务协议
	if(isMobile && !(['/privacy', '/agreement', '/liuliuNet'].includes(to.path))) {
		window.location.href = process.env.VUE_APP_MOBILE_HOME_PATH;
	}
	if(to.meta.auth) {
		if(isUserLoggedIn()) {
			store.dispatch('GetInfo').then(res => {
				next()
			})
		}
		else {
			next('/')
		}
	}
	else {
		//针对首页的设置需要获取用户信息
		if(to.meta.home) {
			if(isUserLoggedIn()) {
				store.dispatch('GetInfo').then(res => {})
			}
		}
		next()
	}
})
  // 在main.js中
  /* 路由异常错误处理，尝试解析一个异步组件时发生错误，重新渲染目标页面 */
router.onError((error) => {
	const pattern = /Loading chunk (\d)+ failed/g
	const isChunkLoadFailed = error.message.match(pattern)
	const targetPath = router.history.pending.fullPath
	if (isChunkLoadFailed) {
		// 用路由的replace方法，
		// 并没有相当于F5刷新页面，
		// 失败的js文件并没有从新请求，
		// 会导致一直尝试replace页面导致死循环，
		// 而用 location.reload 方法，相当于触发F5刷新页面，
		// 虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，
		// 从而曲线救国解决该问题
		window.location.reload()
		// router.replace(targetPath);
	}
})







export default router
