<template>
	<div class="material-page">
		<div class="primary">
			<el-tabs v-model="activeName" class="demo-tabs">
				<el-tab-pane v-for="item in modelTree" :label="item.nodeName" :name="item.nodeId"></el-tab-pane>
			</el-tabs>
			<el-tabs class="demo-tabs" v-model="secondaryActivedId">
				<el-tab-pane label="全部" name=""></el-tab-pane>
				<el-tab-pane v-for="item in secondaryList" :label="item.nodeName" :name="item.nodeId"></el-tab-pane>
			</el-tabs>
		</div>
		<div class="image-list">
			<div 
				class="container" 
				v-infinite-scroll="loadImages" 
				:infinite-scroll-immediate="false" 
				:infinite-scroll-distance="0"
				:infinite-scroll-delay="500"
				:infinite-scroll-disabled="loadDisabled"
			>
				<div class="image-wrap" v-for="(item, index) in imagesList" :key="index"  @click="handleClickImage(item.imgUrl)">
					<img :src="item.imgUrl" alt="">
					<p>{{ item.name }}</p>
				</div>
				
				<div class="zoom-img" v-show="isZoom" @click="() => isZoom = false">
					<img :src="currImage" >
				</div>
				
				
			</div>
			<div class="page-loading" >
				<img src="@/assets/loading.gif" v-show="listLoading">
			</div>
		</div>
	</div>
</template>
	
<script setup>

import Constant from '@/constant/constant';
import {onMounted, ref, computed, watch} from 'vue';
import {getTreeApi, getModelImageApi} from '@/api/login';
import {useStore} from "vuex";
const pageSize = 20;
const imgClipQuery = '?x-oss-process=image/resize,m_fill,w_300/rotate,0';
const currImage = ref('');
const activeName = ref('10');
const secondaryActivedId = ref('');
const modelTree = ref();
const imagesList = ref([]);
const isZoom = ref(false);
const store = useStore()
const currPage = ref(1);
const listLoading = ref(true);
const loadDisabled = ref(false);

const isFullData = ref(false);


onMounted(() => {
	getTreeData();
});

watch([()=>activeName.value, () => secondaryActivedId.value], (newVal, oldVal) => {
	isFullData.value = false;
	// 一级分类改变
	if(newVal[0] !== oldVal[0]) {
		secondaryActivedId.value = '';
	}
	getModelImage(newVal[1] || newVal[0]);
});

const handleClickImage = (image) => {
	if(!checkLogin()) {
		return;
	}
	isZoom.value = true;
	currImage.value = image;
	
}
const checkLogin = () => {
	if(!isLogin.value) {
		store.commit('SET_LOGIN_MODAL_VISIBLE', {
			visible: true,
			type: 'wxLogin',
			path: '/materials',
		});
		return false;
	}
	return true
}
const isLogin = computed(() => {
	return localStorage.getItem(Constant.HttpAccessToken) || false;
})
const secondaryList = computed(() => {
	return modelTree?.value?.find(item => item.nodeId === activeName.value)?.children?.filter(item => item.state === 1) || [];
})
const getTreeData = () => {
	const params = {
		bizCode: 'model_tree',
		nodeId: '0'
	}
	getTreeApi(params).then(res => {
		modelTree.value = res.result.children?.filter(res => {
			return res.state === 1
		})
	}).catch((err) => {
		
	});
}

const loadImages = () => {
	// 当请求数量满过一次后不再请求
	if(isFullData.value) {
		return;
	}
	if(!checkLogin()) {
		return;
	}
	listLoading.value = true;
	const params = {
		categoryList: [activeName.value],
		isHome: 1,
		page: currPage.value,
		size: pageSize
	}
	getModelImageApi(params).then(res => {
		if(res?.result?.length) {
			imagesList.value = [...imagesList.value, ...res?.result?.map(item => {
				item.imgUrl = `${process.env.VUE_APP_OSSURL}${item.preview}${imgClipQuery}`;
				return item;
			}), ];
			// 如果请求数量不满当前分页最大值，不在请求，解决频繁触发登陆校验
			if(res?.result?.length < pageSize) {
				isFullData.value = true;
			}
			else {
				currPage.value += 1;
			}
			
		}
		// 无数据不再请求
		else {
			loadDisabled.value = true;
		}
		listLoading.value = false;
	}).catch(err=>listLoading.value = false)
}

const getModelImage = (cateVal) => {
	listLoading.value = true;
	const params = {
		categoryList: [cateVal],
		isHome: 1,
		page: 1,
		size: pageSize
	}
	getModelImageApi(params).then(res => {
		imagesList.value = res?.result?.map(item => {
			item.imgUrl = `${process.env.VUE_APP_OSSURL}${item.preview}${imgClipQuery}`;
			return item;
		}) || [];
		if(res?.result?.length < pageSize) {
			isFullData.value = true;
		}
		else {
			currPage.value +=1;
		}
		
		listLoading.value = false;
		loadDisabled.value = false;
	}).catch(err=>listLoading.value = false)
}
getModelImage(activeName.value)


</script>
	
<style lang="scss" scoped>
*ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
$theme-color: #cf3861;
:deep(.el-tabs__item ){
  	color: #fff;
  	&:hover {
		color: $theme-color;
	}
}

:deep(.el-tabs__item.is-active ){
  color: $theme-color;
}

:deep(.el-tabs__active-bar) {
	background-color: $theme-color;
}
:deep(.el-tabs__nav-scroll) {
	display: flex;
	justify-content: center;
}
:deep(.el-tabs__header) {
	// width: 100%;
	// border: none;
}
.demo-tabs {
	display: flex;
	justify-content: center;
	width: 100%;
}
.container {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	// height: 100%;
	// overflow-y: auto;
	.image-wrap {
		width: 300px;
		margin: 0 12px;
		
		img {
			background: rgba(255,255,255,.2);
			cursor: pointer;
		}
		>p {
			padding: 5px 0;
			max-height: 48px;
			overflow: hidden;
			line-height: 24px;
			color: #fff;
			text-align: center;
			font-size: 16px;
			background-color: #cf3861;
			border-radius: 16px;
		}
		
	}
}
.zoom-img {
	width:100%;
	height: 100%;
	position:fixed;
	top: 0;
	left: 0;
	background: rgba(0,0,0,.6);
	img {
		width: 60%;
		max-height: 70%;
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		right: 0;
		margin: auto;
	}
}
.material-page {
	.image-list {
		padding: 24px;
		box-sizing: border-box;
		.page-loading {
			height: 100px;
			text-align: center;
			>img {
				height: 100%;
			}
		}
	}
}
</style>