<template>
	<div class="content" v-show="store.state.user.loginModalVisible">
		<div class="form">
			<div class="left"></div>
			<div class="right">
				<div class="right-wrapper">
					<el-icon class="close-icon"  @click="handleClose"><Close /></el-icon>
				<div class="titleBox">
					<h2 :class="{hActive: registerType == 'wxLogin'}" class="freeH" 
					v-show="registerType == 'register'||registerType == 'wxLogin'"  @click="hClick('wxLogin')">{{hasUserCount ? '微信登陆' : '微信注册'}}</h2>

					<h2 :class="{hActive: registerType == 'register' }" class="freeH" 
					v-show="registerType == 'register'||registerType == 'wxLogin'"  @click="hClick('register')">{{hasUserCount ? '快捷登录' : '手机注册'}}</h2>

					<h2 :class="{centerH2:true,hActive: registerType == 'login' }"
					 v-show="registerType == 'login'" class="freeH" @click="hClick('login')">账号登录</h2>

					<h2 :class="{centerH2:true,hActive: registerType == 'registerlogin' }"
					 v-show="registerType == 'registerlogin'" class="freeH">忘记密码</h2>

					<h2 :class="{centerH2:true,hActive: registerType == 'bind' }"
					 v-show="registerType == 'bind'" class="freeH">绑定手机</h2>
				</div>
				<div class="menu">
					<Register @ForgetPassPage="ForgetPassPage"  v-if="registerType == 'register'" />
					<LoginForm @ForgetPassWord="ForgetPassWord" v-else-if="registerType == 'login'" />
					<WxLogin @ForgetPassWord="ForgetPassWord" v-else-if="registerType == 'wxLogin'" />
					<BindPhone @ForgetPassWord="ForgetPassWord" v-else-if="registerType == 'bind'" />
					
					<ForgetPass
						@ForgetPassPage="ForgetPassPage"
						v-else-if="registerType == 'registerlogin'"
					/>
				</div>
				<!-- <div class="switchover" v-show="hasUserCount">
					<span @click="goToLogin" v-show="registerType == 'register'||registerType == 'wxLogin'">账号登陆</span>
					<span @click="goToRegister" v-show="registerType == 'login' || registerType == 'registerlogin' || registerType == 'bind'">快捷登录</span>
				</div> -->
				</div>
				
				<div class="register-content" v-if="hasUserCount">
					<img 
						title="账号登录"
						class="login-logo" 
						src="@/assets/images/logo3.png" 
						alt=""
						@click="goToLogin"
					>
					 没有账号？ <el-button type="primary" link @click="toggleAccount(false)">免费注册</el-button>
				</div>
				<div class="register-content" v-else>
					已有账号？ <el-button type="primary" link @click="toggleAccount(true)">立即登陆</el-button>
				</div>
				
			</div>
			
		</div>
	</div>
</template>
	
<script setup >
import { computed } from 'vue';
import LoginForm from '../login_form/LoginForm.vue';
import Register from '../register/Register.vue';
import BindPhone from '../bindPhone/bindPhone.vue';
import WxLogin from '../wxLogin/wxLogin.vue';
import ForgetPass from '../forgetPass/ForgetPass.vue';
import {useStore} from "vuex";

const store = useStore()
const registerType = computed(() => {
	return store.state.user.registerType;
});
const hasUserCount = computed(() => {
	return store.state.user.hasUserCount;
});

const goToLogin = () => {
	store.commit('HAS_USER_COUNT', true);
	store.commit('SET_REGISTER_TYPE', 'login');
}
const goToRegister = () => {
	store.commit('SET_REGISTER_TYPE', 'register');
}
const hClick = (i) => {
	store.commit('SET_REGISTER_TYPE', i);
}

const ForgetPassPage = () => {
	store.commit('SET_REGISTER_TYPE', 'login');
}
const ForgetPassWord = () => {
	store.commit('SET_REGISTER_TYPE', 'registerlogin');
}
const handleClose = () => {
	store.commit('SET_LOGIN_MODAL_VISIBLE', false);
}
const toggleAccount = (val) => {
	hClick('wxLogin');
	store.commit('HAS_USER_COUNT', val);
}


</script>
	
<style lang="scss" scoped>
.menu {
	height: calc(100% - 60px);
}
.titleBox {
	overflow: hidden;
	cursor: pointer;
	.freeH {
		color: rgb(146, 152, 159);
		width: 50%;
		float: left;
		text-align: center;
		margin: 0;
	}
	.hActive {
		color: #409eff;
	}
	.centerH2 {
		text-align: center;
		width: 100%;
	}
}
	.content {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
    display: flex;
    align-items: center;
	justify-content: center;
    z-index: 9;
	background: rgba($color: #000000, $alpha: 0.5);
    .form {
		box-sizing: border-box;
		width: 800px;
		height: 400px;
		background: url("../../../assets/login.jpg");
		background-size: auto 100%;
		border-radius: 8px;
		position: fixed;
		opacity: .95;
		.right {
			box-sizing: border-box;
			width: 350px;
			height: 100%;
			background-color: #fff;
			border-radius: 8px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			position: absolute;
			right: 0;
			.right-wrapper {
				padding: 38px 30px;
			}
		}
		.close-icon {
			position: absolute;
			cursor: pointer;
			right: 20px;
			top: 20px;
			z-index: 3;
		}
    }
	.register-content {
		background: #f8f8f8;
		text-align: center;
		padding: 12px 0;
		box-sizing: border-box;
		font-size: 14px;
		display: flex;
		justify-content: center;
		position: relative;
		.login-logo {
			height: 20px;
			position: absolute;
			left: 20px;
			cursor: pointer;
			>img {
				height: 100%;
			}
		}
	}
	.switchover {
    display: flex;
    justify-content: space-between;
 	color: #409eff;
    span {
      	cursor: pointer;
		font-size: 12px;
    }
    .icon {
      width: 13px;
      height: 11px;
    }
  }
}
.custom-button {
	background-color: #cf3861;
	border: #cf3861;
}
</style>