<template>
  <div class="right_content">
    <div class="headerTitle">
        <span v-if="materialType === Constant.MaterialPerson">个人材质上传</span>
        <span v-if="materialType === Constant.MaterialSchool">院校材质上传</span>
        <span v-if="materialType === Constant.MaterialCompany">企业材质上传</span>
        <span v-if="materialType === Constant.MaterialPublic">公共材质上传</span>
    </div>
    <div class="uploadBox">
        <el-upload
            class="upload-demo"
            drag
            action
            multiple
            :show-file-list="false"
            accept=".png,.jpg,.jpeg"
            :before-upload="beforeAvatarUpload"
            :http-request="() => 'cover'"
            :on-exceed="exceedLimit"
            :limit="6"
            :on-change="changeFun"
        >
            <el-icon  class="el-icon--upload"><UploadFilled /></el-icon>
            <div class="el-upload__text"><span style="color:red;">点击或拖拽文件到此处上传</span></div>
            <ul class="textBox">
                <li class="el-upload__text">-不超过<span style="color:red;">5M</span></li>
                <li class="el-upload__text">-每次最多上传<span style="color:red;">6个</span>贴图</li>
                <li class="el-upload__text">-支持<span style="color:red;">jpg.jpeg.png</span>格式</li>
            </ul>
        </el-upload>
    </div>
    <div class="itemsList" v-if="items">
        <div class="infoItem" v-for="(i,index) in items" :key="index">
            <div class="infoImg">
                <div class="imgBox">
                    <img :src="OSSURL + i.preview" alt="">
                </div>
                <div class="btnBox" @click="deleteBtn(index)">
                    <el-icon><Close /></el-icon>
                </div>
                <!-- <div class="loading">
                    <el-icon  v-if="!i.path" class="is-loading"><Loading /></el-icon>
                    <el-icon v-else><Check /></el-icon>
                </div> -->
            </div>
            <div class="infoText" @click="edit(i)">
                <div class="modelTitle">
                    {{i.name}} 
                </div>
                <div class="lengthBox">
                    <div class="smaTitle">
                        <span style="color:red;">*</span>
                        长度: {{i.length}} 
                    </div>
                    <div class="smaTitle">
                        <span style="color:red;">*</span>
                        宽度: {{i.width}} 
                    </div>
                </div>
                <div class="modelTitle">
                    <span style="color:red;">*</span>
                    分类: {{i.categoryName}} 
                </div>
            </div>
        </div>
    </div>
    <div class="submitBox" v-if="items.length > 0">
        <el-button type="primary" @click="submitAll">提交</el-button>
    </div>
        <el-dialog
        v-model="dialogVisible"
        title="编辑信息"
        width="400"
        :before-close="handleClose"
        :close-on-click-modal="false"
        >
            <el-form :model="form" label-width="60px" ref="ruleFormRef" :rules="rules">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name"  placeholder="请输入"/>
                </el-form-item>
                <el-form-item label="长度" prop="length">
                    <el-input v-model="form.length"  placeholder="请输入"/>
                </el-form-item>
                <el-form-item label="宽度" prop="width">
                    <el-input v-model="form.width"  placeholder="请输入"/>
                </el-form-item>
                <el-form-item label="分类" prop="category">
                    <el-cascader v-model="form.category" :options="options" :props="props" clearable 
                    ref="cascaderRef" :show-all-levels="false" @change="casChange" style="width:100%;"/>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="handleClose">取消</el-button>
                    <el-button type="primary" @click="onSubmit(ruleFormRef)"
                    >提交</el-button
                    >
                </span>
            </template>
        </el-dialog>
        
        <el-dialog
        v-model="dialogSize"
        title="输入尺寸"
        width="500"
        :before-close="sizeClose"
        :close-on-click-modal="false"
        >
            <el-form :model="sizeForm" label-width="60px" ref="sizeFormRef" :rules="rules">
                <div class="formText">
                    <h3>请确认您本次导入贴图的尺寸</h3>
                    <p>稍后可针对每张贴图单独修改</p>
                </div>
                <el-form-item label="长度" prop="length">
                    <el-input v-model.number="sizeForm.length" type="number" style="width:calc(100% - 40px)" placeholder="请输入"/>
                    <span style="margin-left:10px">mm</span>
                </el-form-item>
                <el-form-item label="宽度" prop="width">
                    <el-input v-model.number="sizeForm.width" type="number" style="width:calc(100% - 40px)" placeholder="请输入"/>
                    <span style="margin-left:10px">mm</span>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="sizeClose">取消</el-button>
                    <el-button type="primary" @click="sizeSubmit(sizeFormRef)"
                    >提交</el-button
                    >
                </span>
            </template>
        </el-dialog>
  </div>
</template>

<script>
import {useRoute,useRouter} from 'vue-router'
import Constant from '@/constant/constant';
import { ref, reactive, } from 'vue'
import dataFactory from '@/api/dataFactory'
import { UploadFile, genID , OSSURL} from '../../util/file'
import api from '@/api/api'
import messageBox from '@/constant/message';
export default {
  components: {
  },
  setup() {
    
    const rules = reactive({
        category: [{ required: true, message: '分类不能为空', trigger: 'change' }],
        position: [{ required: true, message: '位置不能为空', trigger: 'change' }],
        length: [{ required: true, message: '长度不能为空', trigger: 'blur' }],
        width: [{ required: true, message: '宽度不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
    })
    const storeList = ref([])
    const ruleFormRef = ref()
    const sizeFormRef = ref()
    const cascaderRef = ref()
    const resetName = ref()
    const props = {
        // multiple: true,
        checkStrictly: true,
        children: 'children',
        label: 'nodeName',
        value:'nodeId',
    }
    const dialogVisible = ref(false)
    const dialogSize =ref(false)
    const options = ref([])
    const modelPosition = ref([])
    const form = ref({})
    const sizeForm = ref({
        length:1000,
        width:1000,
    })
    const route = useRoute()
    const router = useRouter()
    const items = ref([])
    const uploadList = ref([])
    const materialType = Number(route.query.materialType)

    //function
    //获取字典值
    dataFactory.DictList([Constant.DictCodeModelPosition,]).then(res => {
        modelPosition.value = res[Constant.DictCodeModelPosition]
    }).catch((err) => {
        
    });
    //获取分类
    const DictFaList = () =>{
        api.TreeView({bizCode:Constant.TreeTexture,nodeId:'0',}).then((resp) => {
            options.value = resp?.result.children || []
        }).catch((err) => {
            
        });
    }
    DictFaList()
    //模型分类处理结果
    const casChange = (i) => {
        form.value.category = i.slice(-1)[0]
        form.value.categoryName = cascaderRef.value.getCheckedNodes()[0].data.nodeName
    }
    //位置处理结果
    const changeGateway = (i) => {
        form.value.positionName = i
    }
    
    //模型分类处理结果
    const edit = (i) => {
        resetName.value = i.name
        form.value = {
            ...i
        }
        dialogVisible.value = true
    }
    //最大上传数
    const exceedLimit = (files, fileList) => {
      messageBox.error("超过最大上传个数");
    }
    //删除
    const deleteBtn = (i) => {
        messageBox.elMessageBox().then(() => {
            items.value.splice(i,1)
            uploadList.value.splice(i,1)
            messageBox.success('删除成功!');
        }).catch((err) => {
            messageBox.info('已取消删除!');
        });
    }
    //关闭弹窗
    const handleClose = () => {
        form.value = {
            name: resetName.value,
            categoryName: null,
            category: null,
        }
        dialogVisible.value = false
    }
    const sizeClose = () => {
        storeList.value.forEach(item => {
            let index = uploadList.value.findIndex(elem => elem.uid === item.uid);
            if (index !== -1) {
                uploadList.value.splice(index, 1);
            }
        })
        storeList.value = [] 
        dialogSize.value = false
    }
    //提前生成列表
    const changeFun = (file, fileList) => {
        uploadList.value = fileList
    }
    //上传以后
    const beforeAvatarUpload = async (file) => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            messageBox.error("上传头像图片大小不能超过 5MB!");
            return
        }
        storeList.value.push(file)
        dialogSize.value = true
    }
    const sizeSubmit = (formEl) => {
        
        if (!formEl) return
        formEl.validate((valid) => {//对表单进行一个校验，接收一个回调函数，返回promise
            if (valid) {
                storeList.value.forEach(async (res) => {
                    let pos = res.name.indexOf('.')
                    let txt = res.name
                    if(pos>-1){
                        txt = txt.slice(0,pos)
                    }
                    const arr = res.name.split( '.' )
                    const fileName = genID() + '.' + arr[arr.length - 1] //文件名
                    const preview = await UploadFile( fileName, res )
                    items.value.push({
                        ...sizeForm.value,
                        preview:preview,
                        name:txt,
                        uid:res.uid,
                    })
                })
                dialogSize.value = false
            } else {
                return false
            }
        })
    }
    //确定提交
    const onSubmit = (formEl) => {
        if (!formEl) return
        formEl.validate((valid) => {//对表单进行一个校验，接收一个回调函数，返回promise
            if (valid) {
                items.value.forEach((item,index)=>{
                    if(item.uid===form.value.uid){
                        item.name = form.value.name
                        item.categoryName = form.value.categoryName
                        item.category = form.value.category
                        item.length = form.value.length
                        item.width = form.value.width
                    }
                })
                dialogVisible.value = false
            } else {
                return false
            }
        })
    }
    //提交所有
    const submitAll = () => {
        for(let i = 0; i<items.value.length; i++) {
            if(!items.value[i].categoryName || !items.value[i].length || !items.value[i].width){
                messageBox.error(`请填写第${i+1}条数据分类和数据`);
                return
            }
        }
        for(let i = 0; i<items.value.length; i++) {
            items.value[i].category = parseInt(items.value[i].category)
        }
        let params = {
            group: parseInt(route.query.materialType),
            items: items.value,
        }
        
        api.TextureUpload(params).then((resp) => {
            router.push({ path: '/m/materialManage/personMaterial',query:{active:Constant.TreeTexture}})
        }).catch((err) => {
            ;
        });
    }
    return {
        options,
        props,
        modelPosition,
        form,
        sizeForm,
        items,
        dialogVisible,
        dialogSize,
        rules,
        ruleFormRef,
        sizeFormRef,
        cascaderRef,
        OSSURL,
        materialType,
        Constant,
        onSubmit,
        sizeSubmit,
        casChange,
        beforeAvatarUpload,
        exceedLimit,
        changeFun,
        deleteBtn,
        edit,
        handleClose,
        sizeClose,
        changeGateway,
        submitAll,
    }
  },
}
</script>
<style lang="scss" scoped>
.formText {
    margin-left: 20px;
}
.right_content {
    padding-top: 0;
    padding-bottom: 0;
    text-align: left;
    background-color: #fafbfe;
    min-height: calc(100% - 68px);
    display: flex;
    flex-direction: column;
    .headerTitle {
        padding: 10px 30px;
        font-size: 20px;
        font-weight: 700;
        color: #409eff;
    }
    .submitBox {
        margin: 20px auto 0;
    }
    .itemsList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        margin-left: -20px;
        .infoItem {
            width: 258px;
            height: 330px;
            padding-bottom: 20px;
            background: #fff;
            box-shadow: 0 0 20px 0 rgba(14,14,14,.06);
            margin-top: 20px;
            margin-left: 20px;
            position: relative;
            .infoImg {
                margin: 20px;
                width: 220px;
                height: 206px;
                border-radius: 4px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                .imgBox {
                    width: 100%;
                    height: 100%;
                    img {
                        width: 100%;
                        height: 100%;

                    }
                }
                .btnBox {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 2px 2px 0 0;
                    font-size: 20px;
                    flex-direction: row;
                    color: #fff;
                    display: flex;
                }
                .loading {
                    position: absolute;
                    top: calc(50% - 17px);
                    right: calc(50% - 17px);
                    font-size: 20px;
                    flex-direction: row;
                    color: #ff9f41;
                    display: flex;
                    i {
                        font-size: 34px;
                    }
                }
            }
            .infoText {
                margin-top: 0;
                margin-left: 19px;
                margin-right: 19px;
                overflow: hidden;
                .lengthBox {
                    margin-bottom: 10px;
                    font-size: 14px;
                    font-weight: 700;
                    opacity: .8;
                    .smaTitle {
                        overflow: hidden;
                        width: 50%;
                        display: inline-block;
                    }
                }
                .modelTitle {
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 14px;
                    font-weight: 700;
                    opacity: .8;
                    margin-bottom: 10px;
                }
            }
        }
    }
  .uploadBox {
    width: 100%;
    text-align: center;
    .textBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        .el-upload__text {
            width: 180px;
            font-size: 12px;
            text-align: left;
            list-style-type:disc;
            color: rgb(26, 122, 248);
        }
    }
  }
}
:deep(.el-upload-dragger)  {
    padding: 0;
}
</style>
