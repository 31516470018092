<template>
    <div class="right_content">
        <div class="header">
        </div>
        <div class="footerBox">
            <div class="leftInfo">
                <div class="tit">
                    <p class="bigTitle">
                    方案分组
                    </p>
                    <p class="addTitle">
                    <el-popover placement="bottom" :width="160" trigger="click" :visible="visiblePop">
                        <el-input
                            ref="newAddInput"
                            size="small"
                            placeholder="新建分组"
                            v-model="groupName"
                        ></el-input>
                        <div class="folderBtn">
                            <el-button size="small" @click="cancleName" >取消</el-button>
                            <el-button type="primary" size="small" @click="keyDownRename(1)">确定</el-button>
                        </div>
                        <template #reference>
                            <el-button icon="FolderAdd" type="primary" @click="visiblePop=ture" size="small">新建</el-button>
                        </template>
                    </el-popover>
                    <span>
                    </span>
                    </p>
                </div>
                <ul class="cursor">
                    <span v-for="item in fatherList" :key="item.id">
                        <li @click="showChild(item.id)" v-if="item.isDefault===Constant.SchemeTrue" :class="active===item.id?'active':''">{{item.name}}</li>
                        <li @click="showChild(item.id)" v-if="item.isDefault===Constant.SchemeFalse" :class="active===item.id?'active':''">
                            <span class="folderBtnBox"> 
                                <p>
                                    {{item.name}}
                                </p>
                                <span>
                                    <el-button link icon="Edit" @click="editFolder(item)" />
                                    
                                <el-popover placement="top" :width="160" trigger="click" :ref="el => getFolderRef(el, item.id)">
                                    <p>是否删除这条数据?</p>
                                    <div style="text-align: right; margin: 0">
                                    <el-button size="small" text @click="folderRefRefList[item.id].hide()">取消</el-button>
                                        <el-button size="small" type="primary" @click="folderDelete(item.id,Constant.StateDelete)">确定</el-button>
                                    </div>
                                    <template #reference>
                                        <el-button link  icon="Delete"  @click="visible = true"/>
                                    </template>
                                </el-popover>
                                </span>
                            </span>
                        </li>
                    </span>
                </ul>
            </div>
            <div class="rightInfo">
                <div class="tableBox">
                    <div class="content" v-if="dataList">
                        <div class="infoItem" v-for="(i,index) in dataList" :key="index">
                            <div class="infoImg">
                                <div class="imgBox">
                                    <img :src="OSSURL+i.preview" alt="">
                                </div>
                                <div class="designBtn">
                                    <el-button type="" @click="routerToDesign(i)">去设计</el-button>
                                </div>
                                <div class="btnBox">
                                    <el-button type="primary" size="small"  @click="edit(i.id)">详情</el-button>
                                    <el-popover placement="bottom" :width="160" trigger="click" :ref="el => getMoveRef(el, i.id)">
                                        <div class="popItem" v-for="(item,index) in fatherList" :key="index" @click="moveToFolder(item.id,i.id)">
                                            {{item.name}}
                                        </div>
                                        <template #reference>
                                            <el-button type="danger" size="small">移动</el-button>
                                        </template>
                                    </el-popover>
                                    <el-button type="danger" size="small"   @click="deleteBtn(i.id)">删除</el-button>
                                </div>
                            </div>
                            <div class="infoText">
                                <div class="modelTitle">
                                    方案名称: {{i.name}}
                                </div>
                                <div class="modelTitle">
                                    时间: {{i.designTime}} 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="empt" v-else>
                        暂无数据
                    </div>
                    <div class="pageBox" v-if="total > 0" >
                        <page 
                            :current-page="query.page" 
                            :page-size="query.size" 
                            :total-items="total"
                            @current="handleCurrentChange" 
                            @size="handleSizeChange" 
                        />
                    </div>
                </div>
            </div>
            <el-dialog
                v-model="floderVisible"
                title="修改分组名称"
                width="300"
                :before-close="handleClose"
                :close-on-click-modal="false"
            >
                <el-input v-model="groupName"  placeholder="请输入"/>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="handleClose">取消</el-button>
                        <el-button type="primary" @click="keyDownRename"
                        >确认</el-button
                        >
                    </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import api from '@/api/api'
import { ref,reactive } from 'vue'
import messageBox from '@/constant/message';
import Constant from '@/constant/constant';
import { OSSURL} from '../../util/file'
import page from '@/components/common/page';
import searchForm from '@/components/common/search';
import {useRouter} from 'vue-router'
import { useStore } from 'vuex';
import PersonPerm from '@/constant/personPerm';
export default {
  components: {
    page,searchForm,
  },
  setup() {
    const vue = useStore()
    const userStatus = PersonPerm.design()
    const toDesign = PersonPerm.toDesign()
    const userId = vue.state.user.userView.id
    const loading = ref(false)
    const router = useRouter()
    const ruleFormRef = ref()
    const fatherList = ref([])
    const groupName = ref(null)
    const visiblePop = ref(false)
    const floderVisible = ref(false)
    const active = ref(null)
    const folderId = ref(null)
    const folderRefRefList = ref([]);
    const moveRefList = ref([]);
    const dataList = ref([])
    const total = ref(0)
    const query = ref({
        page: Constant.PaginationDefaultPage,
        size: Constant.PaginationDefaultSize,
    })
    const getFolderRef = (el, index) => {
        if (el) {
            folderRefRefList.value[index] = el; 
        }
    };
    const getMoveRef = (el, index) => {
        if (el) {
            moveRefList.value[index] = el; 
        }
    };
    
    const showChild = (id) => {
        active.value = id
        query.value.folderId = id
        getDataList()
    }
    const TagFaList = async () =>{
         api.FolderList().then((resp) => {
            fatherList.value = resp?.result || []
            active.value = fatherList.value[0].id
            folderId.value = fatherList.value[0].id
            query.value = {
                ...query.value,
                folderId:folderId.value,
            }
            getDataList()
        }).catch((err) => {
        });
    }
    TagFaList()
    //获取列表数据
    const getDataList = (form) => {
        api.ProjectList({...query.value,...form}).then((resp) => {
          dataList.value = resp.result
          total.value = resp.total
        }).catch((err) => {
            ;
        });
    }
    const edit = (id) => {
        router.push({ path: '/m/schemeManage/schemeManage', query: { id: id }})
    }
    //移动
    const moveToFolder = (folderId,id) => {
          api.ProjectMove({folderId: folderId, id: id}).then((resp) => {
            messageBox.success('移动成功!');
            moveRefList.value[id].hide()
            getDataList()
          }).catch((err) => {
            moveRefList.value[id].hide()
            ;
          });
    }
    
    //删除
    const deleteBtn = (id) => {
        messageBox.elMessageBox().then(() => {
          api.ProjectState({id: id, state: Constant.StateDelete}).then((resp) => {
            messageBox.success('删除成功!');
            getDataList()
          }).catch((err) => {
            ;
          });
        }).catch((err) => {
            messageBox.info('已取消删除!');
        });
    }
    const folderDelete = (id,state) => {
        api.FolderState({id:id,state:state}).then((res) => {
            messageBox.success('修改成功')
            TagFaList()
            folderRefRefList.value[id].hide()
        }).catch((err) => {
            
        });
    }
    const editFolder = (item) => {
        groupName.value = item.name
        folderId.value = item.id
        floderVisible.value = true
    }
    const keyDownRename = (i) => {
        if(!groupName.value) {
            messageBox.error('请填写分组名称')
            return
        }
        let params = {}
        if(i === 1) {
            params = {
                name:groupName.value,
                id: 0
            }
        }else {
            params = {
                name:groupName.value,
                id: folderId.value || null
            }
        }
        api.FolderSave(params).then((resp) => {
            visiblePop.value = false
            floderVisible.value = false
            messageBox.success('修改成功')
            TagFaList()
        }).catch((err) => {
            
        });

    }
    const cancleName = () => {
        groupName.value = null
        visiblePop.value = false
    }
    const handleClose = () => {
        folderId.value = null
        groupName.value = null
        floderVisible.value = false
    }
    const handleCurrentChange = (val) => {
        query.value.page = val
        getDataList()
    }
    const handleSizeChange = (val) => {
        query.value.size = val
        getDataList()
    }
    const routerToDesign = (i) => {
      if(userStatus) {
        window.open(process.env.VUE_APP_DESIGN_URL+`?id=${i.id}&user_id=${userId}`)
      }else {
        messageBox.error(toDesign)
      }
    };
    return {
        fatherList,
        ruleFormRef,
        loading,
        groupName,
        visiblePop,
        floderVisible,
        active,
        Constant,
        folderId,
        folderRefRefList,
        moveRefList,
        OSSURL,
        dataList,
        total,
        query,
        keyDownRename,
        cancleName,
        editFolder,
        getFolderRef,
        getMoveRef,
        folderDelete,
        edit,
        deleteBtn,
        moveToFolder,
        getDataList,
        handleClose, //关闭弹窗
        handleCurrentChange,
        handleSizeChange,
        showChild,
        routerToDesign,
    }
  },
}
</script>
<style>
</style>
<style lang="scss" scoped>
.folderBtn {
    text-align: right;
    margin-top: 10px;
}
.folderBtnBox {
    p {
        width:100px; 
        overflow:hidden;
        white-space: nowrap; /* 禁止文本换行 */
        text-overflow: ellipsis; /* 在文本末尾添加省略号 */
        display:inline-block; 
        margin:0;
    }
    span {
        float:right; 
        margin-right:10px;
    }
}
.popItem {
    padding: 5px 0;
    cursor: pointer;
    padding-left: 10px;
    &:hover {
        background: #409EFF;
        color: #fff;
    }
}
.cursor {
    cursor: pointer;
}
.right_content {
    padding-top: 0;
    padding-bottom: 0;
    text-align: left;
    background-color: #fff;
    min-height: calc(100% - 68px);
    display: flex;
    flex-direction: column;
    .footerBox {
      display: flex;
      width: 100%;
      justify-content: space-between;
      height: calc(100% - 150px);
    }
    .rightInfo {
        display: flex;
        flex-direction: column;
        -webkit-box-flex: 1;
        flex-grow: 1;
        position: relative;
        width: 100%;
        padding: 20px 0px 26px 26px;
        margin-right: 28px;
        .tableBox {
            margin-top: 30px;
            width: 100%;
            .empt {
                text-align: center;
                font-size: 20px;
                margin-top: 40px;
            }
            .pageBox {
                display: flex;
                margin:20px 0 ;

            }
            .content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                margin-left: -20px;
                .infoItem {
                width: 258px;
                height: 280px;
                padding-bottom: 20px;
                background: #fff;
                box-shadow: 0 0 20px 0 rgba(14,14,14,.06);
                margin-top: 20px;
                margin-left: 20px;
                position: relative;
                .infoImg {
                    margin: 20px;
                    width: 220px;
                    height: 206px;
                    border-radius: 4px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .imgBox {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: fill;
                        }
                    }
                    .designBtn {
                        z-index: 99;
                        display: none;
                    }
                    .btnBox {
                        position: absolute;
                        bottom: 10px;
                        right: 0;
                        padding: 10px 10px 0 0;
                        font-size: var(--smallFont);
                        flex-direction: row;
                        color: #fff;
                        display: none;
                    }
                }
                .infoText {
                    margin-top: 0;
                    margin-left: 19px;
                    margin-right: 19px;
                    overflow: hidden;
                    .modelTitle {
                        max-width: 200px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                        font-weight: 700;
                        opacity: .8;
                        margin-bottom: 10px;
                    }
                }
                
                &:hover {
                    color: #1a7af8;
                    .imgBox {
                        background: rgba(137, 207, 240,.2);
                    }
                    .btnBox {
                        display: flex;
                    }
                    .designBtn {
                        display: flex;
                    }
                }
                }
            }
        }
        .addButton {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
        .tableBox {
            margin-top: 60px;
            width: 100%;
        }
    }
    .leftInfo {
        flex-shrink: 0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        width: 216px;
        box-sizing: border-box;
        position: relative;
        padding: 24px 0px;
        border-right: 1px solid rgb(237, 238, 240);
        height: 100%;
        .tit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 60px;
          .bigTitle {
            font-size: 18px;
            color: #000;
            font-weight: 700;
          }
          .addTitle {
            width: 80px;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }
        ul {
            margin: 0px;
            padding: 0px;
            list-style: none;
            box-sizing: border-box;
            background: rgb(255, 255, 255);
            font-size: 12px;
            color: rgb(96, 101, 108);
            font-variant: tabular-nums;
            font-feature-settings: "tnum";
            overflow: hidden;
            li {
                margin-bottom: 6px;
                padding:10px 0px;
                padding-left: 35px;
                list-style: none;
                position: relative;
                display: flex;
                flex-direction: column;
                font-size: 16px;
                &:hover {
                    color: rgb(96, 101, 108);
                    background-color: rgba(146, 152, 159, 0.08);
                }
            }
            .active {
                color: rgb(26, 122, 248);
                background-color: rgba(26, 122, 248, 0.08);
                &:hover {
                    color: rgb(26, 122, 248);
                    background-color: rgba(26, 122, 248, 0.08);
                }
            }
        }
    }
}
</style>
