<template>
  <div class="right_content">
    <div class="headerTitle">
        <span v-if="materialType === Constant.MaterialPerson">个人模型上传</span>
        <span v-if="materialType === Constant.MaterialSchool">院校模型上传</span>
        <span v-if="materialType === Constant.MaterialCompany">企业模型上传</span>
        <span v-if="materialType === Constant.MaterialPublic">公共模型上传</span>
    </div>
    <div class="uploadBox">
        <el-upload
            class="upload-demo"
            drag
            action
            multiple
            :show-file-list="false"
            accept=".max,.rar,.zip,.obj,.fbx,.skp"
            :before-upload="beforeAvatarUpload"
            :http-request="() => 'cover'"
            :on-exceed="exceedLimit"
            :limit="6"
            :on-change="changeFun"
        >
            <el-icon  class="el-icon--upload"><UploadFilled /></el-icon>
            <div class="el-upload__text"><span style="color:red;">点击或拖拽文件到此处上传</span></div>
            <ul class="textBox">
                <li class="el-upload__text">-单个文件大小不超过<span style="color:red;">200M</span></li>
                <li class="el-upload__text">-每次最多上传<span style="color:red;">6个</span>模型</li>
                <li class="el-upload__text">-仅支持<span style="color:red;">max、rar、zip、obj、fbx、skp</span> 文件格式</li>
            </ul>
        </el-upload>
    </div>
    <div class="itemsList" v-if="items">
        <div class="infoItem" v-for="(i,index) in items" :key="index">
            <div class="infoImg">
                <div class="imgBox">
                </div>
                <div class="btnBox" @click="deleteBtn(index)">
                    <el-icon><Close /></el-icon>
                </div>
                <div class="loading">
                    <el-icon  v-if="!i.path" class="is-loading"><Loading /></el-icon>
                    <el-icon v-else><Check /></el-icon>
                </div>
            </div>
            <div class="infoText" @click="edit(i)">
                <div class="modelTitle">
                    {{i.name}} 
                </div>
                <div class="modelTitle">
                    <span style="color:red;">*</span>
                    分类: {{i.categoryName}} 
                </div>
                <div class="modelTitle">
                    <span style="color:red;">*</span>
                    位置: {{i.positionName}} 
                </div>
            </div>
        </div>
    </div>
    <div class="submitBox" v-if="items.length > 0">
        <el-button type="primary" @click="submitAll">提交</el-button>
    </div>
            <el-dialog
            v-model="dialogVisible"
            title="编辑信息"
            width="500"
            :before-close="handleClose"
            :close-on-click-modal="false"
        >
            <el-form :model="form" label-width="120px" ref="ruleFormRef" :rules="rules">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name"  placeholder="请输入"/>
                </el-form-item>
                <el-form-item label="模型分类" prop="category">
                    <el-cascader v-model="form.category" :options="options" :props="props" clearable 
                    ref="cascaderRef" :show-all-levels="false" @change="casChange" style="width:100%;"/>
                </el-form-item>
                <el-form-item label="模型摆放位置" prop="position">
                    <el-select v-model="form.position"  style="width:100%;">
                        <el-option v-for="i in modelPosition" :key="i.key" :label="i.value" :value="i.key" @click.native="changeGateway(i.value)"/>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="handleClose">取消</el-button>
                    <el-button type="primary" @click="onSubmit(ruleFormRef)"
                    >提交</el-button
                    >
                </span>
            </template>
        </el-dialog>
  </div>
</template>

<script>
import {useRoute,useRouter} from 'vue-router'
import api from '@/api/api'
import Constant from '@/constant/constant';
import { ref, reactive, } from 'vue'
import dataFactory from '@/api/dataFactory'
import { UploadFile} from '../../util/file'
import messageBox from '@/constant/message';
export default {
  components: {
  },
  setup() {
    
    const rules = reactive({
        category: [{ required: true, message: '分类不能为空', trigger: 'change' }],
        position: [{ required: true, message: '位置不能为空', trigger: 'change' }],
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
    })
    const ruleFormRef = ref()
    const cascaderRef = ref()
    const resetName = ref()
    const props = {
        // multiple: true,
        checkStrictly: true,
        children: 'children',
        label: 'nodeName',
        value:'nodeId',
    }
    const dialogVisible = ref(false)
    const options = ref([])
    const modelPosition = ref([])
    const form = ref({})
    const route = useRoute()
    const router = useRouter()
    const items = ref([])
    const uploadList = ref([])
    const materialType = Number(route.query.materialType)

    //function
    //获取字典值
    dataFactory.DictList([Constant.DictCodeModelPosition,]).then(res => {
        modelPosition.value = res[Constant.DictCodeModelPosition]
    }).catch((err) => {
        
    });
    //获取分类
    const DictFaList = () =>{
        api.TreeView({bizCode:Constant.TreeModel,nodeId:'0',}).then((resp) => {
          options.value = resp.result.children?.filter(res => {
            return res.state === 1
          }).map(item => {
            item.children = item.children.filter(items => items.state === 1)
            return item
          }) || []
        }).catch((err) => {
            
        });
    }
    DictFaList()
    //模型分类处理结果
    const casChange = (i) => {
        form.value.category = i.slice(-1)[0]
        form.value.categoryName = cascaderRef.value.getCheckedNodes()[0].data.nodeName
    }
    //位置处理结果
    const changeGateway = (i) => {
        form.value.positionName = i
    }
    
    //模型分类处理结果
    const edit = (i) => {
        resetName.value = i.name
        form.value = {
            ...i
        }
        dialogVisible.value = true
    }
    //最大上传数
    const exceedLimit = (files, fileList) => {
      messageBox.error("超过最大上传个数");
    }
    //删除
    const deleteBtn = (i) => {
        messageBox.elMessageBox().then(() => {
            items.value.splice(i,1)
            uploadList.value.splice(i,1)
            messageBox.success('删除成功!');
        }).catch((err) => {
            messageBox.info('已取消删除!');
        });
    }
    //关闭弹窗
    const handleClose = () => {
        form.value = {
            name: resetName.value,
            categoryName: null,
            category: null,
            position: null,
            positionName: null,
        }
        dialogVisible.value = false
    }
    //提前生成列表
    const changeFun = (file, fileList) => {
        uploadList.value = fileList
        let pos = file.name.indexOf('.')
        let txt = file.name
        if(pos>-1){
            txt = txt.slice(0,pos)
        }
        items.value.push({
            name:txt,
            uid:file.uid,
        })
    }
    //上传以后
    const beforeAvatarUpload = async (file) => {
        const isLt5M = file.size / 1024 / 1024 < 200;
        if (!isLt5M) {
            messageBox.error("上传头像图片大小不能超过 200MB!");
            return
        }
        let path = await UploadFile( file.name, file )
        items.value.forEach((item,index)=>{
          if(item.uid===file.uid){
            item.path=path
          }
        })
    }
    
    //确定提交
    const onSubmit = (formEl) => {
        if (!formEl) return
        formEl.validate((valid) => {//对表单进行一个校验，接收一个回调函数，返回promise
            if (valid) {
                items.value.forEach((item,index)=>{
                    if(item.uid===form.value.uid){
                        item.name = form.value.name
                        item.categoryName = form.value.categoryName
                        item.category = form.value.category
                        item.position = form.value.position
                        item.positionName = form.value.positionName
                    }
                })
                dialogVisible.value = false
            } else {
                return false
            }
        })
    }
    //提交所有
    const submitAll = () => {
        for(let i = 0; i<items.value.length; i++) {
            if(!items.value[i].categoryName || !items.value[i].positionName){
                messageBox.error(`请填写第${i+1}条数据分类和数据`);
                return
            }
        }
        for(let i = 0; i<items.value.length; i++) {
            items.value[i].category = parseInt(items.value[i].category)
            items.value[i].position = parseInt(items.value[i].position)
        }
        let params = {
            group: parseInt(route.query.materialType),
            items: items.value,
        }
        
        api.ModelUpload(params).then((resp) => {
            router.push({ path: '/m/materialManage/personMaterial'})
        }).catch((err) => {
            ;
        });
    }
    return {
        options,
        props,
        modelPosition,
        form,
        items,
        dialogVisible,
        rules,
        ruleFormRef,
        cascaderRef,
        materialType,
        Constant,
        onSubmit,
        casChange,
        beforeAvatarUpload,
        exceedLimit,
        changeFun,
        deleteBtn,
        edit,
        handleClose,
        changeGateway,
        submitAll,
    }
  },
}
</script>
<style lang="scss" scoped>
.right_content {
    padding-top: 0;
    padding-bottom: 0;
    text-align: left;
    background-color: #fafbfe;
    min-height: calc(100% - 68px);
    display: flex;
    flex-direction: column;
    .headerTitle {
        padding: 10px 30px;
        font-size: 20px;
        font-weight: 700;
        color: #409eff;
    }
    .submitBox {
        margin: 20px auto 0;
    }
    .itemsList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        margin-left: -20px;
        .infoItem {
            width: 258px;
            height: 320px;
            padding-bottom: 20px;
            background: #fff;
            box-shadow: 0 0 20px 0 rgba(14,14,14,.06);
            margin-top: 20px;
            margin-left: 20px;
            position: relative;
            .infoImg {
                margin: 20px;
                width: 220px;
                height: 206px;
                border-radius: 4px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                .imgBox {
                    width: 100%;
                    height: 100%;
                    background: rgb(224, 224, 224);
                }
                .btnBox {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 2px 2px 0 0;
                    font-size: 20px;
                    flex-direction: row;
                    color: #fff;
                    display: flex;
                }
                .loading {
                    position: absolute;
                    top: calc(50% - 17px);
                    right: calc(50% - 17px);
                    font-size: 20px;
                    flex-direction: row;
                    color: #ff9f41;
                    display: flex;
                    i {
                        font-size: 34px;
                    }
                }
            }
            .infoText {
                margin-top: 0;
                margin-left: 19px;
                margin-right: 19px;
                overflow: hidden;
                .modelTitle {
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 14px;
                    font-weight: 700;
                    opacity: .8;
                    margin-bottom: 10px;
                }
            }
        }
    }
  .uploadBox {
    width: 100%;
    text-align: center;
    .textBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        .el-upload__text {
            width: 180px;
            font-size: 12px;
            text-align: left;
            list-style-type:disc;
            color: rgb(26, 122, 248);
        }
    }
  }
}
:deep(.el-upload-dragger)  {
    padding: 0;
}
</style>
