
import { ElMessage, ElMessageBox } from 'element-plus';
import {debounce} from '@/util/common.js';
const messageBox = {
    error(err) {		
		ElMessage({
			type: 'error',
			message: err,
			grouping: true
		})

    },
    success(err) {
        ElMessage.success(err)
    },
    info(err) {
        ElMessage.info(err)
    },
    elMessageBox() {
        return ElMessageBox.confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
    },
}

export default messageBox