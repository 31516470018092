<template>
    <div class="sidebar_box">
        <el-menu :default-active="path" class="el-menu-vertical-demo">
            <router-link to="">
                <el-menu-item  index="1">
                    <template #title>
                        <el-icon><Promotion /></el-icon>
                        <span style="width: 100%" @click="handleToAI">AI</span>
                    </template>
                </el-menu-item>
            </router-link>
            <router-link to="">
                <el-menu-item index="2">
                    <template #title>
                        <el-icon><List /></el-icon>
                        <span style="width: 100%" @click="handleToHelp">帮助</span>
                    </template>
                </el-menu-item>
            </router-link>
            <!-- <router-link to="">
			<el-menu-item>
			<template #title>
				<el-icon><List /></el-icon>
				<span style="width: 100%;" @click="handleMission">任务大厅</span>
			</template>
			</el-menu-item>
		</router-link> -->

            <span v-for="(item, index) in pathList" :key="index">
                <!-- 不存在children -->
                <template v-if="!item.children?.length">
                    <router-link
                        :to="'/' + item.path"
                        :key="item.path"
                        v-if="UserPerm.hasPerm(item.code)"
                    >
                        <el-menu-item :index="'/' + item.path">
                            <template #title>
                                <el-icon>
                                    <component
                                        :is="iconName(item.code)"
                                    ></component>
                                </el-icon>
                                <span>{{ item.label }}</span>
                            </template>
                        </el-menu-item>
                    </router-link>
                </template>
                <template v-else>
                    <el-sub-menu
                        :index="item.path"
                        v-if="UserPerm.hasPerm(item.code)"
                    >
                        <template #title>
                            <el-icon>
                                <component
                                    :is="iconName(item.code)"
                                ></component>
                            </el-icon>
                            <span>{{ item.label }}</span>
                        </template>
                        <span v-for="ele in item.children" :key="ele.path">
                            <!-- <router-link
                  :to="'/'+item.path+'/'+ele.path"
                  :key="ele.meta.title"> -->
                            <router-link
                                :to="'/' + item.path + '/' + ele.path"
                                :key="ele.meta.title"
                                v-if="
                                    UserPerm.hasPerm(ele.meta.code) &&
                                    ele.meta.show
                                "
                            >
                                <el-menu-item
                                    :index="'/' + item.path + '/' + ele.path"
                                >
                                    <!-- <el-icon>
                      <component :is="iconName(ele.meta.code)"></component>
                    </el-icon> -->
                                    <span>{{ ele.meta.title }}</span>
                                </el-menu-item>
                            </router-link>
                        </span>
                    </el-sub-menu>
                </template>
            </span>
        </el-menu>
    </div>
</template>

<script>
import {getTreeApi} from '@/api/login';
import Constant from "@/constant/constant";
import UserPerm from "@/constant/userPerm";
import { useRouter, useRoute } from "vue-router";
import { ref } from "vue";
export default {
    setup() {
        // 获取后台管理页面路由
        const router = useRouter().options?.routes?.find(
            (item) => item.name === "manage"
        )?.children;
        console.log(router)
        const { path } = useRoute();
        const pathList = ref([]);
        const menuList = ref([]);
        menuList.value = Constant.MenuList();
        const nodeId = ref(0)
        getTreeApi({bizCode:Constant.TreeArcticle,nodeId:'0',}).then((resp) => {
            nodeId.value = resp.result.children[0].nodeId
        }).catch((err) => {
        });
        // 适配渲染数据
        router?.map((res) => {
            if (res.path === "/" || res.path === "/login") {
                return;
            }
            if (res.children?.length > 0) {
                pathList.value.push({
                    label: res.meta.title,
                    path: "m/" + res.path,
                    code: res.meta.code,
                    children: res.children,
                });
            } else {
                pathList.value.push({
                    label: res.meta.title,
                    path: "m/" + res.path,
                    code: res.meta.code,
                    children: [],
                });
            }
        });

        //function
        const iconName = (code) => {
            let icon = "";
            menuList.value.forEach((item) => {
                if (code === item.code) {
                    icon = item.icon;
                }
            });
            return icon;
        };

        const handleMission = () => {
            window.open(process.env.VUE_APP_MISSION_DOMAIN,'_blank')
        };
        const handleToAI = () => {
            window.open(process.env.VUE_APP_AI_DOMAIN,'_blank')
        };
		const handleToHelp = () => {
            window.open(process.env.VUE_APP_HELP_DOMAIN + '/category/' + nodeId.value,'_blank')
		}

        return {
            path,
            pathList,
            Constant,
            UserPerm,
            nodeId,
            iconName,
            handleMission,
            handleToAI,
			handleToHelp,
        };
    },
};
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
a {
    text-decoration: none !important;
}

.sidebar_box {
    left: 0;
    width: var(--sideWidth);
    overflow-y: auto;
    position: absolute;
    height: calc(100% - var(--headHeight));
    top: var(--headHeight);
    background: #f3f4f6;
    box-shadow: 0px 6px 11px 0px rgba(14, 14, 14, 0.2);
    position: fixed;
    height: calc(100% - 60px);
    overflow: auto;
}
</style>
